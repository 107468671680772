import { Heading, Input, Table, TableBody, TableHead, TableRow, Td, Text, Th } from '@happyfoxinc/react-ui'
import { Fragment, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './ManageGDrive.module.css'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AppFooter from '../../../components/AppFooter'
import { InstallAppBase } from '../../../components/InstallApp'
import GDriveSyncTable from './GDriveSyncTable'
import GDriveTableOptions from './GDriveTableOptions'

const ManageGDrive = ({ app }) => {
  const [folderId, setFolderId] = useState('')
  const { currentWorkspaceId } = useWorkspace()
  const [excludeGDriveFolder] = api.useExcludeGDriveFolderMutation()
  const { data: excludedFolders = [] } = api.useGetExcludedGDriveFoldersQuery({ workspace_id: currentWorkspaceId })

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const [uninstall, uninstallResult] = api.useUninstallGDriveMutation()

  const handleUninstall = () => {
    const promise = uninstall({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling Google Drive',
      success: 'Google Drive integration uninstalled successfully',
      error: 'Unable to uninstall Google Drive. Try again'
    })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Folder ID',
        accessor: 'id'
      },
      {
        Header: 'Folder Name',
        accessor: 'name'
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: excludedFolders
  })

  const handleExcludeClick = () => {
    const promise = excludeGDriveFolder({ folder_id: folderId, workspace_id: currentWorkspaceId }).unwrap()

    toast.promise(promise, {
      loading: 'Excluding folder',
      success: 'Folder excluded successfully',
      error: 'Unable to exclude folder. Try again'
    })

    promise.then(() => setFolderId(''))
  }

  return (
    <Fragment>
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to Google Drive.
          <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.linked_email}
          </Text>
        </Heading>
      </InstallAppBase>
      <Fragment>
        <Text>Folders currently syncing from</Text>
        <GDriveSyncTable app={app} />
      </Fragment>

      <Fragment>
        <Text>Exclude folders </Text>
        <div className={styles.ExcludeContainer}>
          <Input
            placeholder='Enter folder ID to exclude'
            value={folderId}
            onChange={(e) => setFolderId(e.target.value)}
          />
          <SecondaryButton onClick={handleExcludeClick}>Exclude</SecondaryButton>
        </div>
      </Fragment>
      {excludedFolders.length > 0 && (
        <Fragment>
          <Text>Excluded folders </Text>
          <Table {...getTableProps()} className={styles.ExcludedFoldersTable}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
                return (
                  <TableRow key={key} {...headerGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps()

                      return (
                        <Th key={key} {...headerProps} className={styles.TableHeader}>
                          {column.render('Header')}
                        </Th>
                      )
                    })}
                    <Th className={styles.TableHeader} isOption />
                  </TableRow>
                )
              })}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()
                const id = row.original.id
                return (
                  <TableRow key={key} {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps()
                      return (
                        <Td key={key} {...cellProps} className={styles.ExcludedFoldersTableTD}>
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}
                    <Td className={styles.ExcludedFoldersTableTD}>
                      <GDriveTableOptions id={id} />
                    </Td>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Fragment>
      )}
      <AppFooter
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
        app={app}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message='Are you sure you want to uninstall Google Drive?'
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
    </Fragment>
  )
}

export default ManageGDrive
