import { Flex, Heading, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { Fragment, useState } from 'react'

import styles from './Reports.module.scss'

import AiIcon from 'Icons/ai-text.svg'
import RightAngleIcon from 'Icons/arrow-right.svg'
import CheckIcon from 'Icons/check-orange.svg'
import CoverageIcon from 'Icons/coverage.svg'
import DownloadIcon from 'Icons/download.svg'
import HelpIcon from 'Icons/help-orange.svg'
import ListIcon from 'Icons/list.svg'
import MessageCheckIcon from 'Icons/message-check.svg'
import MessageIcon from 'Icons/message.svg'
import NotHelpfulIcon from 'Icons/not-helpful.svg'
import ThumbsUpIcon from 'Icons/thumbs-up.svg'
import TwoBarIcon from 'Icons/two-bar.svg'
import UserIcon from 'Icons/user-orange.svg'

import { SecondaryButton } from 'Components/Buttons'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'
import ProgressBar from 'Components/ProgressBar'
import { ACCOUNT_TYPE } from 'Constants/account'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import { paramsSerializer } from 'Src/api'
import { openUrl } from 'Utils/url'

import ContentList from './ContentList'
import ContentListWithCount from './ContentListWithCount'
import ContentOutline from './ContentOutline'
import IntervalSelection from './IntervalSelection'
import INTERVALS from './IntervalSelection/interval-configs'
import { METRICS } from './metric-list'
import Tile from './Tile'

const MetricTable = ({
  primaryCount,
  othersCount,
  totalCount,
  othersDisabled = false,
  othersNotApplicable = false
}) => {
  const { data: account = {} } = api.useGetAccountQuery()
  const accountType = account?.account_type === ACCOUNT_TYPE.SLACK ? 'Slack' : 'MS Teams'

  const isOtherCountValid = !!othersCount && !isNaN(othersCount)
  const isOtherDisabledAndCountPresent = othersDisabled && isOtherCountValid

  let othersValue = othersCount
  if (othersNotApplicable) {
    othersValue = 'N/A*'
  } else if (othersDisabled && !isOtherCountValid) {
    othersValue = 'Not Enabled'
  }

  const othersValueDisabled = othersDisabled || othersNotApplicable
  const othersValueNoCount = isNaN(othersValue)

  let othersLabel = 'Other Channels'
  if (!othersNotApplicable && isOtherDisabledAndCountPresent) {
    othersLabel += ' (Not Enabled)'
  }

  return (
    <Fragment>
      <div className={styles.MetricRow}>
        <div className={styles.RowLabel}>{accountType}</div>
        <div className={styles.RowValue}>{primaryCount}</div>
      </div>
      <div className={styles.MetricRow}>
        <div className={styles.RowLabel}>{othersLabel}</div>
        <div
          className={cx(styles.RowValue, {
            [styles.RowValueDisabled]: othersValueDisabled,
            [styles.RowValueNoCount]: othersValueNoCount
          })}
        >
          {othersValue}
        </div>
      </div>
      <div className={cx(styles.MetricRow, styles.TotalRow)}>
        <div className={styles.RowLabel}>Total</div>
        <div className={styles.RowValue}>{totalCount}</div>
      </div>
    </Fragment>
  )
}

const BigNumber = ({ value, isPercent = false }) => {
  return (
    <div className={styles.BigTextContainer}>
      <div className={styles.BigNumber}>
        {value}
        {isPercent && `%`}
      </div>
      {isPercent && (
        <div className={styles.ProgressBar}>
          <ProgressBar percent={value} />
        </div>
      )}
    </div>
  )
}

const BigWord = ({ value }) => {
  return (
    <div className={styles.BigTextContainer}>
      <div className={styles.BigWord}>{value}</div>
    </div>
  )
}

const Reports = () => {
  const { isEnterprisePlan } = usePlanContext()
  const isNonEnterprisePlan = !isEnterprisePlan

  const { data: account = {} } = api.useGetAccountQuery()
  const accountType = account?.account_type === ACCOUNT_TYPE.SLACK ? 'Slack' : 'MS Teams'
  const isTicketingSystemConnected = account.is_ticketing_integration_connected
  const trackExternalTickets = account.track_external_tickets
  const showSuggestionsForExternalTickets = account.show_suggestion_for_external_tickets
  const isTicketingSystemConnectedAndEnabled = isTicketingSystemConnected && trackExternalTickets
  const showAutoResolutionMetrics =
    isEnterprisePlan && isTicketingSystemConnectedAndEnabled && showSuggestionsForExternalTickets

  const getDefaultInterval = () => INTERVALS.find(({ isDefault }) => isDefault)
  const [interval, setInterval] = useState(getDefaultInterval)

  const getDefaultQueryParams = () => ({
    interval: interval.value
  })
  const [queryParams, setQueryParams] = useState(getDefaultQueryParams)

  const { isLoading, isFetching, data = {} } = api.useGetReportMetricsQuery(queryParams)
  const reports = { ...data }

  if (!isTicketingSystemConnectedAndEnabled) {
    reports.requests_from_other_sources_disabled = true
    reports.tickets_created_from_other_sources_disabled = true
  }

  if (isNonEnterprisePlan) {
    reports.suggestions_from_other_sources_not_applicable = true
    reports.resolved_requests_with_feedback_from_other_sources_not_applicable = true
  } else if (!(isTicketingSystemConnectedAndEnabled && showSuggestionsForExternalTickets)) {
    reports.suggestions_from_other_sources_disabled = true
    reports.resolved_requests_with_feedback_from_other_sources_disabled = true
  }

  const handleIntervalChange = (selectedInterval) => {
    setInterval(selectedInterval)

    const { value: interval } = selectedInterval
    setQueryParams({
      ...queryParams,
      interval
    })
  }

  const downloadUrlForArticlesRequested = `/api/v1/requested-kb/export?${paramsSerializer(queryParams)}`

  return (
    <Fragment>
      <Heading>Reports</Heading>
      <Flex align='center' justify='between'>
        <Text variant='muted'>
          Overview of various metrics tied to your account for{' '}
          <Text className={styles.SelectedInterval} as='span'>
            {interval.label}
          </Text>
        </Text>
        <IntervalSelection onChange={handleIntervalChange} value={interval} />
      </Flex>

      <PageLoader isLoading={isLoading || isFetching}>
        <div className={styles.TileContainer}>
          <div className={styles.MetricTile}>
            <Tile title={METRICS.REQUEST_METRICS.label} info={METRICS.REQUEST_METRICS.info} icon={<MessageIcon />}>
              <ContentOutline title='Number of requests'>
                <MetricTable
                  primaryCount={reports.requests_count_from_primary_sources}
                  othersCount={reports.requests_count_from_other_sources}
                  totalCount={reports.requests_count}
                  othersDisabled={reports.requests_from_other_sources_disabled}
                />
              </ContentOutline>
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile title={METRICS.USERS.label} info={METRICS.USERS.info({ accountType })} icon={<UserIcon />}>
              <ContentOutline title='Number of active users'>
                <BigNumber value={reports.active_users_count} />
              </ContentOutline>
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile title={METRICS.TOTAL_SUGGESTIONS.label} info={METRICS.TOTAL_SUGGESTIONS.info} icon={<AiIcon />}>
              <ContentOutline title='Number of Suggestions'>
                <MetricTable
                  primaryCount={reports.suggestions_count_from_primary_sources}
                  othersCount={reports.suggestions_count_from_other_sources}
                  totalCount={reports.suggestions_count}
                  othersDisabled={reports.suggestions_from_other_sources_disabled}
                  othersNotApplicable={reports.suggestions_from_other_sources_not_applicable}
                />
              </ContentOutline>
            </Tile>
          </div>

          <div className={styles.MetricTileWider}>
            <Tile title={METRICS.ARTICLES_REQUESTED.label} info={METRICS.ARTICLES_REQUESTED.info} icon={<HelpIcon />}>
              <div className={styles.ContentListContainer}>
                <ContentList list={reports.requested_kb_messages} />
              </div>
              <div className={styles.ActionRow}>
                <SecondaryButton
                  type='button'
                  className={styles.DownloadBtn}
                  size='sm'
                  as='a'
                  href={downloadUrlForArticlesRequested}
                  download
                >
                  Download CSV
                  <DownloadIcon className={styles.DownloadIcon} />
                </SecondaryButton>
              </div>
            </Tile>
          </div>

          <div className={styles.MetricTileTaller}>
            <Tile
              title={METRICS.RESOLUTION_METRICS.label}
              info={METRICS.RESOLUTION_METRICS.info}
              icon={<MessageCheckIcon />}
            >
              <ContentOutline title='Resolved with feedback'>
                <MetricTable
                  primaryCount={reports.resolved_requests_count_with_feedback_from_primary_sources}
                  othersCount={reports.resolved_requests_count_with_feedback_from_other_sources}
                  totalCount={reports.resolved_requests_count_with_feedback}
                  othersDisabled={reports.resolved_requests_with_feedback_from_other_sources_disabled}
                  othersNotApplicable={reports.resolved_requests_with_feedback_from_other_sources_not_applicable}
                />
              </ContentOutline>
              <ContentOutline title='Resolved without feedback'>
                <div className={cx(styles.MetricRow, styles.TotalRow)}>
                  <div className={styles.RowLabel}>Total</div>
                  <div className={styles.RowValue}>{reports.resolved_requests_count_without_feedback}</div>
                </div>
              </ContentOutline>
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile
              title={METRICS.TICKET_DEFLECTION_RATE.label}
              info={METRICS.TICKET_DEFLECTION_RATE.info}
              icon={<CheckIcon />}
            >
              <ContentOutline title='Automated Resolution Rate'>
                <BigNumber value={reports.ticket_deflection_rate} isPercent />
              </ContentOutline>
            </Tile>
          </div>

          {showAutoResolutionMetrics && (
            <Fragment>
              <div className={styles.MetricTile}>
                <Tile
                  title={METRICS.AUTO_TICKET_RES_RATE.label}
                  info={METRICS.AUTO_TICKET_RES_RATE.info}
                  icon={<MessageCheckIcon />}
                >
                  <ContentOutline title='Automated Resolution Rate'>
                    <BigNumber value={reports.ticket_auto_resolution_rate} isPercent />
                  </ContentOutline>
                </Tile>
              </div>

              <div className={styles.MetricTile}>
                <Tile
                  title={METRICS.OVERALL_AUTO_RES_RATE.label}
                  info={METRICS.OVERALL_AUTO_RES_RATE.info}
                  icon={<MessageCheckIcon />}
                >
                  <ContentOutline title='Automated Resolution Rate'>
                    <BigNumber value={reports.overall_auto_resolution_rate} isPercent />
                  </ContentOutline>
                </Tile>
              </div>
            </Fragment>
          )}

          <div className={styles.MetricTile}>
            <Tile title={METRICS.TICKETS_CREATED.label} info={METRICS.TICKETS_CREATED.info} icon={<ListIcon />}>
              <ContentOutline title='Number of tickets created'>
                <MetricTable
                  primaryCount={reports.tickets_created_from_primary_sources}
                  othersCount={reports.tickets_created_from_other_sources}
                  totalCount={reports.tickets_created}
                  othersDisabled={reports.tickets_created_from_other_sources_disabled}
                />
              </ContentOutline>
            </Tile>
          </div>

          {reports.top_category && (
            <div className={styles.MetricTile}>
              <Tile title={METRICS.TOP_CATEGORY.label} info={METRICS.TOP_CATEGORY.info} icon={<TwoBarIcon />}>
                <ContentOutline title='Category with the most number of tickets created'>
                  <BigWord value={reports.top_category} />
                </ContentOutline>
              </Tile>
            </div>
          )}

          <div className={styles.MetricTile}>
            <Tile title={METRICS.COVERAGE_RATE.label} info={METRICS.COVERAGE_RATE.info} icon={<AiIcon />}>
              <ContentOutline title='Coverage Rate'>
                <BigNumber value={reports.coverage_rate} isPercent />
              </ContentOutline>
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile title={METRICS.COVERAGE_OPPS.label} info={METRICS.COVERAGE_OPPS.info} icon={<CoverageIcon />}>
              <ContentList list={reports.coverage_opportunities} />
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile title={METRICS.MOST_HELPFUL_ANS.label} info={METRICS.MOST_HELPFUL_ANS.info} icon={<ThumbsUpIcon />}>
              <ContentListWithCount list={reports.most_helpful_answers} />
            </Tile>
          </div>

          <div className={styles.MetricTile}>
            <Tile
              title={METRICS.LEAST_HELPFUL_ANS.label}
              info={METRICS.LEAST_HELPFUL_ANS.info}
              icon={<NotHelpfulIcon />}
            >
              <ContentListWithCount list={reports.least_helpful_answers} />
            </Tile>
          </div>
        </div>

        {isNonEnterprisePlan && (
          <div className={styles.CallToAction}>
            <span className={styles.Badge}>N/A*</span> <span>Available only in Enterprise plan.</span>
            <SecondaryButton
              size='sm'
              className={styles.UpgradeButton}
              onClick={() => {
                openUrl(URLS.ASSIST_AI)
              }}
            >
              Upgrade Plan <RightAngleIcon width='1em' height='1em' />
            </SecondaryButton>
          </div>
        )}
      </PageLoader>
    </Fragment>
  )
}

export default Reports
