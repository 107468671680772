import { Checkbox, Text } from '@happyfoxinc/react-ui'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import cx from 'classnames'
import React, { forwardRef, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './GDriveSyncTable.module.scss'

import ArrowRightIcon from 'Icons/arrow-right.svg'

import PageLoader from 'Components/PageLoader'
import UserGroupBadge from 'Components/UserGroupBadge'
import { api } from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import { useAppDetailContext } from '../../../AppDetailContext'

export const Accordion = AccordionPrimitive.Root
export const AccordionItem = AccordionPrimitive.Item
const AccordionHeader = AccordionPrimitive.Header
export const AccordionTrigger = forwardRef((props, ref) => {
  return <AccordionPrimitive.Trigger {...props} className={cx(styles.AccordionTrigger, props.className)} ref={ref} />
})
AccordionTrigger.displayName = 'AccordionTrigger'
export const AccordionContent = AccordionPrimitive.Content

const FolderRow = ({ id, name, visibility, sync, depth = 0 }) => {
  const { currentWorkspaceId } = useWorkspace()
  const [isExpanded, setIsExpanded] = useState(false)

  const [syncGDriveFolder] = api.useSyncGDriveFolderMutation()
  const [excludeGDriveFolder] = api.useExcludeGDriveFolderMutation()

  const { data: childFolders, isLoading: childrenLoading } = api.useGetGDriveFoldersQuery(
    { folder_id: id, workspace_id: currentWorkspaceId },
    { skip: !isExpanded || !sync }
  )

  useEffect(() => {
    if (!sync) {
      setIsExpanded(false)
    }
  }, [sync])

  const handleSyncChange = async (prevState) => {
    const mutation = prevState ? excludeGDriveFolder : syncGDriveFolder
    try {
      await toast.promise(mutation({ folder_id: id, workspace_id: currentWorkspaceId }).unwrap(), {
        loading: prevState ? 'Excluding folder' : 'Syncing folder',
        success: prevState ? 'Folder excluded successfully' : 'Folder synced successfully',
        error: prevState ? 'Unable to exclude folder. Try again' : 'Unable to sync folder. Try again'
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error syncing/excluding folder:', error)
    }
  }

  const handleAccordionChange = (value) => {
    if (sync) {
      setIsExpanded(value === id)
    }
  }

  return (
    <Accordion
      type='single'
      collapsible
      className={cx(styles.Accordion, { [styles.AccordionRoot]: depth === 0 })}
      value={isExpanded ? id : ''}
      onValueChange={handleAccordionChange}
    >
      <AccordionItem value={id}>
        <AccordionHeader className={styles.AccordionHeader}>
          <AccordionTrigger disabled={!sync}>
            <span className={styles.AccordionTriggerIcon}>
              <ArrowRightIcon width='1em' height='1em' />
            </span>
          </AccordionTrigger>
          <div className={styles.ContentTitle}>{name}</div>
          <div>
            <UserGroupBadge visibility={visibility} mapped_user_groups={[]} />
          </div>
          <div className={styles.TableOptionsContainer}>
            <Checkbox checked={sync} onCheckedChange={() => handleSyncChange(sync)} />
          </div>
        </AccordionHeader>
        {sync && (
          <AccordionContent className={styles.AccordionContent}>
            {childrenLoading ? (
              <PageLoader size='sm' />
            ) : childFolders && childFolders.length > 0 ? (
              childFolders.map((childFolder) => <FolderRow key={childFolder.id} {...childFolder} depth={depth + 1} />)
            ) : (
              <Text variant='muted' className={styles.EmptyFolder}>
                No folders found
              </Text>
            )}
          </AccordionContent>
        )}
      </AccordionItem>
    </Accordion>
  )
}

const GDriveSyncTable = () => {
  const { title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()
  const { isLoading, data: folderData } = api.useGetGDriveFoldersQuery({ workspace_id: currentWorkspaceId })

  if (isLoading) {
    return <PageLoader />
  }

  if (!folderData || folderData.length === 0) {
    return (
      <div className={styles.ZeroState}>
        <Text>
          Hey! It looks like you haven't synced any folders from <br />
          <Text isInline variant='muted'>
            {title}
          </Text>
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.TableContainer} role='table'>
      <div className={styles.TableHeader}>
        <div>Folders</div>
        <div>Visibility</div>
        <div>Sync</div>
      </div>
      <div className={styles.TableBody}>
        {folderData.map((folder) => (
          <FolderRow key={folder.id} {...folder} />
        ))}
      </div>
    </div>
  )
}

export default GDriveSyncTable
