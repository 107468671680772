// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sp2UQ6Arb_VFmYp_243Q{display:flex;width:60%;gap:20px}.qoVIt1LYiNEt5oQPLsW2{box-shadow:rgba(14,63,126,.06) 0px 0px 0px 1px,rgba(42,51,70,.03) 0px 1px 1px -0.5px,rgba(42,51,70,.04) 0px 2px 2px -1px,rgba(42,51,70,.04) 0px 3px 3px -1.5px,rgba(42,51,70,.03) 0px 5px 5px -2.5px,rgba(42,51,70,.03) 0px 10px 10px -5px,rgba(42,51,70,.03) 0px 24px 24px -8px}.fhKdUeBJPFn5MalE82Ng{padding:.5rem;font-weight:400;background-color:#eee;color:#000}.KfSVmsV8C8HP6zvCEL8b{padding:.5rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/GDrive/ManageGDrive.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,SAAA,CACA,QAAA,CAGF,sBACE,gRAAA,CAGF,sBACE,aAAA,CACA,eAAA,CACA,qBAAA,CACA,UAAA,CAGF,sBACE,aAAA","sourcesContent":[".ExcludeContainer {\n  display: flex;\n  width: 60%;\n  gap: 20px;\n}\n\n.ExcludedFoldersTable {\n  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;\n}\n\n.TableHeader {\n  padding: 0.5rem;\n  font-weight: 400;\n  background-color: #eee;\n  color: black;\n}\n\n.ExcludedFoldersTableTD {\n  padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExcludeContainer": "Sp2UQ6Arb_VFmYp_243Q",
	"ExcludedFoldersTable": "qoVIt1LYiNEt5oQPLsW2",
	"TableHeader": "fhKdUeBJPFn5MalE82Ng",
	"ExcludedFoldersTableTD": "KfSVmsV8C8HP6zvCEL8b"
};
export default ___CSS_LOADER_EXPORT___;
