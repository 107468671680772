import { useState } from 'react'

import { useRedirectConfirmationContext } from 'Src/components/ConfirmationModal/RedirectConfirmationContext'
import { useWorkspace } from 'Utils/hooks/useWorkspace'
import { getDomainFromUrl } from 'Utils/url'

import InstallApp from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'

const InstallGDrive = (props) => {
  const [isInstalling, setIsInstalling] = useState(false)
  const { app } = props
  const { title } = useAppDetailContext()
  const externalRedirect = useRedirectConfirmationContext()
  const { currentWorkspaceId } = useWorkspace()

  const url = new URL(app?.internal_redirect_url)
  url.searchParams.append('workspace_id', currentWorkspaceId)

  const handleInstall = () => {
    setIsInstalling(true)
    externalRedirect(url, {
      handleCancel: () => setIsInstalling(false),
      customDomain: getDomainFromUrl(app.external_redirect_url)
    })
  }

  return <InstallApp name={title} onInstall={handleInstall} isInstalling={isInstalling} />
}

export default InstallGDrive
